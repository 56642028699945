// Import Fuse core library
@import "@fuse/scss/core.scss";

// Import app.theme.scss
@import "app/app.theme.scss";

html,
body {
    height: 100%;
    font-family: "Muli", "Cairo", "Helvetica Neue", "Arial", sans-serif;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "@ng-select/ng-select/themes/material.theme.css";
@import "../node_modules/@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import "simple-keyboard/build/css/index.css";

.owl-dt-inline-container,
.owl-dt-popup-container {
    font-size: 14px;
}

button:focus {
    outline: unset !important;
}

.h-100 {
    height: 100px !important;
    min-height: 100px !important;
    max-height: 100px !important;
    font-weight: 600 !important;
    color: white;
}

.header-font-size {
    font-size: 32px;
    font-weight: 600;
    color: white;

    @media (max-width: 768px) {
        font-size: 20px;
    }
}

.mat-mdc-form-field-hint {
    width: 100%;
    text-align: right;
}

.mat-mdc-form-field-error {
    width: 100%;
    text-align: right;
}

.error-snackbar {
    color: #f5f5f5 !important;
    --mdc-snackbar-container-color: #dd5859 !important;
    max-width: 100vw !important;
    min-width: 0 !important;

    .mat-mdc-snack-bar-container {
        color: #f5f5f5 !important;
        background-color: #dd5859 !important;
    }

    .divButtonError,
    .mat-mdc-button {
        background-color: #c44f50 !important;
        color: #ffffff !important;
        border-radius: 8px !important;
        padding: 3px 10px !important;
        cursor: pointer !important;
        font-size: 13px !important;
        font-weight: bold;
        line-height: 15px !important;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }

    .mdc-button {
        width: 32px !important;
        min-width: unset !important;
    }

    .mat-mdc-simple-snack-bar {
        display: flex !important;
        justify-content: center !important;
    }

    .divButtonWaiting {
        visibility: hidden !important;
        height: 0 !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    .divButtonSuccess {
        visibility: hidden !important;
        height: 0 !important;
        padding: 0 !important;
        margin: 0 !important;
    }
}

.success-message-snackbar {
    color: #f5f5f5 !important;
    --mdc-snackbar-container-color: #48C9B0 !important;
    max-width: 100vw !important;
    min-width: 0 !important;

    .mat-mdc-snack-bar-container {
        color: #f5f5f5 !important;
        // background-color: #8ed455 !important;
        background-color: #7bbd44 !important;
    }

    .divButtonSuccess,
    .mat-mdc-button {
        background-color: #48C9B0 !important;
        color: #ffffff !important;
        border-radius: 8px !important;
        padding: 3px 10px !important;
        cursor: pointer !important;
        font-size: 13px !important;
        font-weight: bold;
        line-height: 15px !important;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }

    .mdc-button {
        width: 32px !important;
        min-width: unset !important;
    }

    .mat-mdc-simple-snack-bar {
        display: flex !important;
        justify-content: center !important;
    }

    .divButtonError {
        visibility: hidden !important;
        height: 0 !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    .divButtonWaiting {
        visibility: hidden !important;
        height: 0 !important;
        padding: 0 !important;
        margin: 0 !important;
    }
}

.info-message-snackbar {
    color: #f5f5f5 !important;
    --mdc-snackbar-container-color: #039be5 !important;
    max-width: 100vw !important;
    min-width: 0 !important;

    .mat-mdc-snack-bar-container {
        color: #f5f5f5 !important;
        background-color: #039be5 !important;
    }

    .divButtonSuccess,
    .mat-mdc-button {
        background-color: #039be5 !important;
        color: #ffffff !important;
        border-radius: 8px !important;
        padding: 3px 10px !important;
        cursor: pointer !important;
        font-size: 13px !important;
        font-weight: bold;
        line-height: 15px !important;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }

    .mdc-button {
        width: 32px !important;
        min-width: unset !important;
    }

    .mat-mdc-simple-snack-bar {
        display: flex !important;
        justify-content: center !important;
    }

    .divButtonError {
        visibility: hidden !important;
        height: 0 !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    .divButtonWaiting {
        visibility: hidden !important;
        height: 0 !important;
        padding: 0 !important;
        margin: 0 !important;
    }
}

::ng-deep .mat-mdc-snack-bar-handset {
    width: auto !important;
}

.mat-mdc-simple-snack-bar {
    font-size: 12px !important;
}

.mat-mdc-snack-bar-container {
    max-width: 100vw !important;
    min-width: 0 !important;
}

.mat-form-field-hint-spacer {
    flex: 0 !important;
}

.mat-mdc-form-field-subscript-wrapper {
    padding: 0 4px !important;
    margin-top: 1px !important;
}

.mat-form-field-appearance-outline {
    .mat-mdc-form-text-infix {
        width: auto !important;
    }
}

::ng-deep.ng-select .ng-select-container {
    overflow: unset !important;
}

::ng-deep.ng-select .ng-select-container.ng-appearance-outline {
    overflow: unset !important;
}

.mat-mdc-form-field,
.mat-mdc-radio-button,
.mat-mdc-checkbox {
    font-size: 14px;
}

.sirh-validate-btn {
    background-color: #039be5 !important;
    color: white !important;
}

.sirh-add-btn {
    background-color: #039be5 !important;
    color: white !important;
}

.sirh-edit-btn {
    background-color: #039be5 !important;
    color: white !important;
}

.sirh-delete-btn {
    background-color: #f44336 !important;
    color: white !important;
}

.sirh-cancel-btn {
    background-color: #f44336 !important;
    color: white !important;
}

.sirh-activate-btn {
    background-color: #43a046 !important;
    color: white !important;
}

.sirh-suspend-btn {
    background-color: #f44336 !important;
    color: white !important;
}

.sirh-import-btn {
    background-color: #039be5 !important;
    color: white !important;
}

.sirh-export-btn {
    background-color: #039be5 !important;
    color: white !important;
}

.sirh-print-btn {
    background-color: #039be5 !important;
    color: white !important;
}

.sirh-calcul-btn {
    background-color: #039be5 !important;
    color: white !important;
}

.sirh-action-dark-btn {
    background-color: #3c4252 !important;
    color: white !important;
}

.sirh-disabled-btn {
    background-color: rgba(0, 0, 0, 0.12) !important;
    color: rgba(0, 0, 0, 0.26) !important;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
    cursor: not-allowed !important;
}

// force regular style for mat-form-field for mdc
.mat-mdc-form-field {
    display: unset !important;
}

.mat-mdc-form-field-infix,
.mdc-text-field--outlined .mdc-notched-outline {
    //   height: 44px !important;
    min-height: 44px !important;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 21px !important;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label .mdc-floating-lable--float-above {
    top: 28px !important;
}

.mat-mdc-form-field .mdc-text-field--outlined .mdc-floating-label--float-above {
    top: 28px !important;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
    height: 100%;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 14px !important;
    padding-bottom: 8px !important;
}

.mat-mdc-form-field-icon-suffix {
    padding-right: 5px !important;
}

.mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label {
    font-size: 14px !important;
    height: 20px;
}

.custome-date-piker-input {
    .mat-mdc-icon-button.mat-mdc-button-base {
        height: 44px !important;
    }

    .mdc-text-field__input {
        padding-top: 3px;

    }
}

//   force style of button inside mat-form-field for mdc
.mat-mdc-form-field-icon-suffix .mat-mdc-icon-button.mat-mdc-button-base {
    height: 42px !important;
    padding: 10px 12px !important;
}

// mat hint error
.mat-error.mat-mdc-form-field-hint {
    color: #f44336 !important;
    font-size: 10.5px !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    line-height: 1;
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
    padding: 0 !important;
}

.mat-mdc-form-field-hint-spacer {
    // display: none !important;
}

// form field icon
.mat-mdc-form-field-icon-prefix>.mat-icon,
.mat-mdc-form-field-icon-suffix>.mat-icon {
    padding: 4px 12px 0 0 !important;
    font-size: 21px;
}

.mat-mdc-option .mat-pseudo-checkbox-minimal {
    margin-right: 16px;
}

.mat-mdc-select-arrow-wrapper {
    height: 18px !important;
}

// froce height in clear btn inside picker date range
.date-range {
    height: 22px !important;
}

/// end ///
///
/// set default color of checkbox
.mat-mdc-checkbox.mat-accent {
    --mdc-checkbox-selected-checkmark-color: #fff !important
}

/// end ///
/// icon standard color
.icon-color-gray {
    button {
        color: #0000008a !important;
    }

    color: #0000008a !important;
}

.mat-mdc-tab {
    margin-left: 25px !important;
}



/* src/styles.css ou src/styles.scss */
body.scanner-active {
    background-color: transparent !important;
}

html.scanner-active {
    --background: transparent !important;
    --ion-background-color: transparent !important;
    background-color: transparent !important;
}


.slide-container {
    height: 100%;
    padding-bottom: 24px;
}